<template>
  <div>
    <div class="heading">
      <h1>{{transHotelName}}</h1>
      <div class="d-flex retingbox" v-if="rating>2">
        <b-form-rating
          class="ui rating"
          v-model="rating"
          variant="warning"
          locale="he"
          readonly
        ></b-form-rating>
      </div>
    </div>
    <!------ kremlin list area start ------->
    <div class="kremlin-list">
      <ul class="d-flex flex-row">
        <li>
          <figure class="kremlin-box">
            <img :src="`${imageDomain}/assets/img/app/icon3.png`" :alt="`${transHotelName} - ${transCityName}`" title="" />
            <figcaption>{{ `${transHotelName} - ${transCityName}` }}</figcaption>
          </figure>
        </li>
        <li>
          <figure class="kremlin-box">
            <img :src="`${imageDomain}/assets/img/app/icon2.png`" :alt="periodVocation" title="" />
            <figcaption>{{ periodVocation }}</figcaption>
          </figure>
        </li>
        <li>
          <figure class="kremlin-box">
            <img :src="`${imageDomain}/assets/img/app/icon1.png`" :alt="paxList" title="" />
            <figcaption>{{ paxList }}</figcaption>
          </figure>
        </li>
      </ul>
    </div>
    <!------ kremlin list area stop ------->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { BFormRating } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  components: {
    BFormRating,
  },
  mixins: [imageUrlMixin],
  data() {
    return {
      periodVocation: '',
      paxList: '',

      transCityName: '',
      transHotelName: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    remark() {
      // return this.product?.remarkTranslation?.[this.lang] ?? this.product?.remark ?? '';
      return this.product.remark ?? '';
    },
    rating() {
      return Number(this.product.hotels[0]?.grade.substr(0, 1));
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    const from = dayjs(this.product.fromDate),
      to = from.add(this.product.duration_1, 'day');
    this.periodVocation = `${from.format('DD/MM/YYYY')}-${to.format('DD/MM/YYYY')}`;
    const { roomList } = JSON.parse(window.sessionStorage.getItem('roomList'));
    let ctAdult = 0, ctChild = 0, ctInfant = 0;
    roomList.forEach((room) => {
      ctAdult += room.adult;
      ctChild += room.child;
      ctInfant += room.infant;
    });
    if (ctAdult) this.paxList += `${this.$t('booking.adults')} ${ctAdult}`;
    if (ctChild) this.paxList += ` | ${this.$t('booking.children')} ${ctChild}`;
    if (ctInfant) this.paxList += ` | ${this.$t('booking.infants')} ${ctInfant}`;

    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      // eslint-disable-next-line no-mixed-operators
      this.transCityName = this.product.translations?.city && this.product.translations?.city[this.product.hotels[0].city][this.lang] || this.product.hotels[0]?.cityName;
      this.transHotelName = this.product.hotels[0]?.hotelName; // this.product.translations.hotelName && this.product.translations.hotelName[this.product.hotels[0].La_ID][this.lang] || this.product.hotels[0].hotelName;
    },
  },
};
</script>

<style scoped>
.heading_area {
  width: 100%;
  padding: 20px 0px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}
.heading_area .heading_body_area h1 {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 600;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h1 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h2 {
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 600;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h2 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h3 {
  color: #ffffff;
  font-size: 14px;
  margin: 0 0 6px;
  letter-spacing: normal;
  font-weight: 400;
  position: relative;
  padding-right: 20px;
}
.heading_area .heading_body_area h3 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.heading_area .heading_body_area h3:last-child {
  margin-bottom: 0px;
}
</style>
